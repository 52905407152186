<!-- 文件中文名: 信用卡支付页面 -->
<template>
  <div class="credit-card-pay app-container">
    <div style="width: 50%;min-width: 300px;margin: 0 auto">
      <!--信用卡支付-->
      <h3>{{ $t('Credit_Card_Payment') }}</h3>
      <el-form :model="listQuery" size="mini" label-width="auto" style="text-align: left">
        <!--订单编号-->
        <el-form-item :label="$t('order.no')">
          {{ PayOnlineData.orderNo }}
        </el-form-item>
        <!--总价格-->
        <el-form-item :label="$t('order.total.price')">
          {{ PayOnlineData.payAmount }} {{$symbol()}}
        </el-form-item>
        <!--      已有信用卡-->
        <el-form-item :label="$t('Existed_Credit_Card')+(':')">
          <el-radio-group v-model="listQuery.creditCard">
            <el-radio v-for="role in creditCardList" :key="role.id" :label="role.id" border
                      style="display: block;margin-left: 0;margin-top: 10px">
              <template>
                {{ $lt(initDict.credit_card_type, role.cardType) }}&nbsp;&nbsp;&nbsp;{{ $t(role.cardAsn) }}&nbsp;&nbsp;&nbsp;{{ role.nameOnCard }}
              </template>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!--      卡类型-->
        <el-form-item v-if="listQuery.creditCard==='aaa'"
                      :label="$t('Card_Type')+(':')" prop="cardType" required>
          <el-select v-model="listQuery.cardType">
            <el-option v-for="item in creditCardTypeList" :key="item.code"
                       :label="$lt(initDict.credit_card_type, item.code)" :value="item.code"/>
          </el-select>
        </el-form-item>
        <!--      是否保存-->
        <el-form-item v-if="listQuery.creditCard==='aaa'"
                      :label="$t('Save_or_Not')+(':')">
          <el-select v-model="listQuery.isSave">
            <el-option v-for="item in yesNoList" :key="item.code"
                       :label="$lt(initDict.yesNo, item.code)" :value="item.code"/>
          </el-select>
        </el-form-item>
        <!--      卡号-->
        <el-form-item
            v-if="listQuery.creditCard==='aaa'&&(listQuery.cardType==='3'||listQuery.cardType==='6'||listQuery.cardType==='150'||listQuery.cardType==='10')"
            :label="$t('title.cardNo')+(':')" prop="cardAsn" required>
          <el-input v-model="listQuery.cardAsn"></el-input>
        </el-form-item>
        <!--      cvv-->
        <el-form-item
            v-if="listQuery.creditCard==='aaa'&&(listQuery.cardType==='6'||listQuery.cardType==='150')"
            label="cvv:"
            prop="cvv" required>
          <el-input v-model="listQuery.cvv"></el-input>
        </el-form-item>
        <!--      有效期-->
        <el-form-item
            v-if="listQuery.creditCard==='aaa'&&(listQuery.cardType==='6'||listQuery.cardType==='150')"
            :label="$t('Validity_Date')+(':')"
            required>
          <el-select v-model="listQuery.validityMonth">
            <el-option v-for="item in MonthList" :key="item" :label="item" :value="item"/>
          </el-select>
          &nbsp;-&nbsp;
          <el-select v-model="listQuery.validityYear">
            <el-option v-for="item in YearList" :key="item" :label="item" :value="item"/>
          </el-select>
        </el-form-item>
        <!--      持卡人姓名-->
        <el-form-item
            v-if="listQuery.creditCard==='aaa'&&(listQuery.cardType==='6'||listQuery.cardType==='150')"
            :label="$t('Cardholder_Name')+(':')"
            prop="nameOnCard" required>
          <el-input v-model="listQuery.nameOnCard"></el-input>
        </el-form-item>
        <!--      持卡人手机-->
        <el-form-item
            v-if="listQuery.creditCard==='aaa'&&(listQuery.cardType==='10'||listQuery.cardType==='6'||listQuery.cardType==='150')"
            :label="$t('Cardholder_Cellphone')+(':')" prop="mobile" required>
          <el-input v-model="listQuery.mobile"></el-input>
        </el-form-item>
        <!--      持卡人邮箱-->
        <el-form-item
            v-if="listQuery.creditCard==='aaa'&&(listQuery.cardType==='10'||listQuery.cardType==='6'||listQuery.cardType==='150')"
            :label="$t('Cardholder_Email')+(':')" prop="email" required>
          <el-input v-model="listQuery.email"></el-input>
        </el-form-item>
      </el-form>
      <div style="width: 100%;text-align: center">
        <!--              信用卡支付-->
        <el-button size="mini" :loading="doLoading" plain type="success" @click="doCreditCardPay">
          {{ $t('Credit_Card_Payment') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>


import {mapGetters} from "vuex";
import {creditCardShowI, onlinePayTon} from "@/api/rus/api";

export default {
  name: 'CreditCardPay',
  directives: {},
  components: {},
  data() {
    return {
      doLoading: false,
      PayOnlineData: null,
      creditCardList: [],
      MonthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      YearList: [],
      listQuery: {
        cardType: '10',
        isSave: null,
        creditCard: null,
        validityYear: null,
        validityMonth: null,
        F$: 0,
        B$: 0,
        money: 0
      },
      initDict: {
        yesNo: 'yesno',
        fiPayAdvicedealType: 'trans_type_cn',
        credit_card_type: 'credit_card_type'
      },
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'companyCode',
    ]),
    creditCardTypeList() {
      return this.$ll(this.initDict.credit_card_type)
    },
    yesNoList() {
      return this.$ll(this.initDict.yesNo)
    }
  },
  watch: {},
  beforeCreate() {
  },
  created() {
    if (!sessionStorage.getItem("PayOnlineData")) {
      console.log('PayOnlineData不存在!')
      this.$router.go(-1)
    }
    this.PayOnlineData = JSON.parse(sessionStorage.getItem("PayOnlineData"))
    console.log(JSON.parse(JSON.stringify(this.PayOnlineData)), 'this.PayOnlineData')
    this.getCreditCardShowI()
    for (let i = 2023; i < 2101; i++) {
      this.YearList.push(i)
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    getCreditCardShowI() {
      creditCardShowI().then(res => {
        console.log(res, 'creditCardShowI')
        this.creditCardList = res.data
        this.creditCardList.push({id: 'aaa', cardAsn: this.$t('Use_New_Card')})
      }).catch(err => {
        console.warn(err);
      })
    },
    doCreditCardPay() {
      this.doLoading = true
      this.listQuery.casdmobile = this.listQuery.mobile

      //信用卡支付
      this.listQuery.validity = this.listQuery?.validityMonth + '-' + this.listQuery?.validityYear
      // console.log(this.listQuery.validity, 'this.listQuery.validity')
      if (this.listQuery.creditCard !== 'aaa') { //选择已有信用卡时
        let selectObj = this.creditCardList.filter(item => item.id === this.listQuery.creditCard)[0]
        // console.log(selectObj, '选择的信用卡信息')
        this.listQuery = {...this.listQuery, ...selectObj}
        this.listQuery.isSave = 0
        this.listQuery.id = null
        // console.log(this.listQuery, '传递的参数')
      }
      // 网关模式时 删除有效期
      if (this.listQuery.cardType==10) {
        delete this.listQuery.validity
      }
      onlinePayTon({...this.PayOnlineData, ...this.listQuery, id: this.PayOnlineData.id}).then(res => {
        this.doLoading = false
        console.log(res, 'onlinePayTon')
        if (res.data.status == 0) {
          // window.open(res.data?.postUrl, '_blank')
          window.location.href = res.data?.postUrl
          // this.$router.push({name: 'AccountHistory'})
        } else {
          this.$alert(res.data?.errManages || res?.data, 'Error', {
            confirmButtonText: this.$t('button.confirm').toString(),
            type: 'error',
            callback: () => {
              this.$message({
                type: 'error',
                message: res.data?.errManages || res?.data
              });
            }
          });
        }
      }).catch(err => {
        console.error(err)
        this.doLoading = false
      })
    },
  }
}

</script>

<style scoped>

</style>
